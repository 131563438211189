import React, { FunctionComponent } from 'react'
import Helmet from 'react-helmet'
import 'normalize.css'
import '../styles/main.css'

type Props = {
	pageContext: {
		deck: {
			title: string
			slug: string
			pitchID: string
		}
	}
}

const DeckTemplate: FunctionComponent<Props> = ({ pageContext }) => {
	const { title, pitchID } = pageContext.deck
	return (
		<div
			css={{
				width: '100vw',
				height: '100vh',
				padding: '5vw',
				boxSizing: 'border-box',
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Helmet>
				<title>{title} | Free Association</title>
			</Helmet>

			<div
				css={{
					width: '100%',
					paddingBottom: 'calc(9.0 / 16.0 * 100%)',
					background: '#333',
					position: 'relative',
				}}
			>
				<iframe
					src={`https://pitch.com/embed/${pitchID}`}
					allow="fullscreen"
					allowFullScreen={true}
					css={{
						width: '100%',
                        height: '100%',
						position: 'absolute',
						left: 0,
						top: 0,
						border: 0,
					}}
				></iframe>
			</div>
		</div>
	)
}

export default DeckTemplate
